<template>
  <div class="container">
    <div class="right"
         :style="fullscreen ? 'width: 118%' : 'width: 100%'">
      <div class="bgImg">
        <div>
          <!-- <div class="bgImg_title">{{uaCompanySigned}}</div> -->
        </div>
        <div class="titleall">
          <div class="bgImg_title">
            <!-- {{uaCompanySigned}} -->
            政企数字化解决方案服务商
          </div>
          <div class="bgImg_title1">
            政企数字媒体解决方案提供商
          </div>
        </div>
      </div>

      <div class="newIntroduce">
        <div class="companyInfo">公司简介：</div>
        <div class="companyInfoText">
          {{ intro }}
          <!-- 【浙江丹道网络科技有限公司】是一家领先的数字科技+数字媒体公司，专注于政府数字治理及企业数字化转型升级，以提供数字化解决方案和运营服务为核心，助力推进社会治理及企业向数字化转型发展。 -->
        </div>
      </div>
      <!-- 公司简介 -->
      <div style="background: #f8f8f8">
        <!-- 业务 -->
        <div class="work">
          <div class="littleBox">
            <div class="work_title">公司业务</div>
            <div class="titles">Business</div>
            <div class="liner_border"></div>
          </div>
          <div class="work_List">
            <div class="work_item"
                 v-for="(item, index) in businessList"
                 :key="index">
              <div class="img"><img :src="item.imgUrl"
                     alt="" /></div>
              <div class="typeName">
                {{ item.typeName }}
              </div>
              <div class="typeContent">
                {{ item.typeContent }}
              </div>
            </div>
          </div>
        </div>
        <!-- 企业文化 -->
        <div class="work"
             style="margin-top: 16px; padding-top: 20px; padding-left: 0">
          <div class="littleBox">
            <div class="work_title"
                 style="padding-left: 20px">
              企业文化
            </div>
            <div class="titles"
                 style="padding-left: 20px">Corporate Culture</div>
            <div class="liner_border"
                 style="margin-left: 20px"></div>

          </div>

          <div class="Culture">
            <div class="Culture_four">
              <!-- 1 -->
              <div class="Culture_four_Item"
                   v-for="(item, index) in cultureList"
                   :key="index">
                <div class="Culture_four_Item_left"
                     v-if="index % 2 == 0">
                  <div class="Culture_four_Item_img">
                    <img :src="item.imgUrl"
                         alt=" " />
                  </div>
                  <div style="width: 100%; text-align: center"
                       class="culTypeName">
                    {{ item.typeName }}
                  </div>
                </div>
                <div class="Culture_four_Item_right"
                     v-if="index % 2 == 0">
                  <span v-html="item.typeContent"></span>
                </div>
                <div class="Culture_four_Item_right"
                     v-if="index % 2 != 0">
                  <span v-html="item.typeContent"></span>
                </div>
                <div class="Culture_four_Item_left"
                     v-if="index % 2 != 0">
                  <div class="Culture_four_Item_img">
                    <img :src="item.imgUrl"
                         alt=" " />
                  </div>
                  <div style="width: 100%; text-align: center"
                       class="culTypeName">
                    {{ item.typeName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 使命和资源 -->
        <div class="work"
             style="margin-top: 12px; padding-top: 20px; padding-left: 0">
          <div class="littleBox">
            <div class="work_title"
                 style="padding-left: 20px">使命和愿景</div>
            <div style="font-size: 18px; text-align: left; color: #000">
              <div class="titles"
                   style="padding-left: 20px">
                Mission and Vision
              </div>
            </div>
            <div class="liner_border"
                 style="margin-left: 20px"></div>
          </div>

          <div class="wish_right">
            <div class="wish_right_Item"
                 style="margin-top: 60px">
              <div class="border2">
                <div></div>
                <div></div>
              </div>
              <div class="wish_right_Item_top">
                <div class="wish_right_Item_title">我们的使命</div>
                <div class="wish_right_Item_img">
                  <img src="../../assets/image/icon/wish_1.png"
                       alt="" />
                </div>
              </div>
              <span>{{mission}}</span>
            </div>

            <div class="wish_right_Item">
              <div class="border2">
                <div></div>
                <div></div>
              </div>
              <div class="wish_right_Item_top">
                <div class="wish_right_Item_title">我们的愿景</div>
                <div class="wish_right_Item_img">
                  <img src="../../assets/image/icon/wish_2.png"
                       alt="" />
                </div>
              </div>
              <span>{{vision}}</span>
            </div>
          </div>
        </div>
        <!-- <div style="  padding-bottom: 40px;color: #969799; font-size:12px;margin-top:20px">丹道提供技术支持</div> -->
        <Fotter></Fotter>
      </div>

    </div>
  </div>
</template>

<script>
import { getHonorList, getNewList, getCompanyInfo, getInfo } from "../../utils/api.js";
import Fotter from "../../components/fotter.vue";
export default {
  components:{
    Fotter,
  },
  data () {
    return {
      businessList: [],
      cultureList: [],
      currentValue: 2,
      activeIndex2: "1",
      key: 1,
      honorList: [],
      query: {
        pageNum: 1,
        pageSize: 1,
      },
      pageTotal: 0,
      data: {},
      fullscreen: false,
      intro: "",
      uaCompanySigned: '',
      vision: '',
      mission: ''
    };
  },
  created () {
    this.getData();
    this.getInfo()
    // this.uaCompanySigned = localStorage.getItem('uaCompanySigned')
    console.log(this.uaCompanySigned);

  },
  methods: {
    getInfo () {
      getInfo({ uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res.data);
          this.uaCompanySigned = res.data.uaCompanySigned
        }
      })
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
      this.$set(this, "key", key);
      if (this.key == 3) {
        console.log(123);
        this.getHonorList();
      } else if (this.key == 4) {
        this.getNewList();
      }
    },
    getHonorList () {
      getHonorList().then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.honorList = res.data;
        }
      });
    },
    getData () {
      getCompanyInfo({
        uaId: 1
      }).then((res) => {
        if (res.code == 20000) {
          this.intro = res.data.intro;
          this.vision = res.data.vision;
          this.mission = res.data.mission;
          for (var i = 0; i < res.data.cultureList.length; i++) {
            res.data.cultureList[i].typeContent = res.data.cultureList[
              i
            ].typeContent.replace(/\n/g, "<br>");
          }
          this.cultureList = res.data.cultureList;
          this.businessList = res.data.businessList;
          console.log(res.data.cultureList[2]);
          // // this.tableData = res.data
          // this.id = res.data.id;
        }
      });
    },
    getNewList () {
      getNewList(this.query).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          res.data.dataList[0].npictureUrl = JSON.parse(
            res.data.dataList[0].npictureUrl
          );
          this.data = res.data.dataList[0];
          console.log(this.data);
          this.pageTotal = res.data.totalRecord;
        }
      });
    },

    //
    ToNewsDetail (id) {
      console.log(id);
      this.$router.push({ path: "/newDetails", query: { id: id } });
    },
    // 分页导航
    handlePageChange (val) {
      this.$set(this.query, "pageNum", val);
      // this.getNewList();
    },
  },
};
</script>

<style scoped >
.container {
  background: #fff;
  width: 100%;
  height: 100%;
  touch-action: manipulation;
}

.right {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
}
.bgImg {
  width: 100%;
  height: 75%;
  background: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/git.gif") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  padding-left: 45px;
  box-sizing: border-box;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgImg2 {
  width: 100%;
  height: 180px;
  background: url("../../assets/image/introduce_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  padding-left: 45px;
  box-sizing: border-box;
}
.titleall {
  /* margin-top: 90px; */
}
.bgImg_title {
  font-size: 40px;
  /* height: 100px; */
  text-align: center;
  font-weight: 500;
line-height: 48px;
}
.bgImg_title1 {
  font-size: 40px;
  margin-top: 12px;
  text-align: center;
  font-weight: 500;
line-height: 48px;
}
.bgImg div {
  color: #fff;
}
.bgImg_title {
  font-size: 45px;
  height: 50px;
}
.bgImg_title2 {
  font-size: 22px;
  height: 40px;
  color: #fff;
}
.bgImg_title3 {
  font-size: 28px;
  margin-left: -45px;
  height: 40px;
  color: #fff;
}
.menus {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 3px solid #f2f2f2;
}

.work {
  width: 100%;
  /* height: calc(100vh -280px);
  overflow: scroll; */
  padding-left: 20px;
  margin-top: 80px;
  /*  */

  box-sizing: border-box;
  background: #fefefe;
}
.littleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.work_List {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.work_title {
  font-size: 30px;
  color: #333232;
  margin: 10px auto 0 auto;
  text-align: center;
  font-weight: 550;
}
.liner_border {
  width: 150px;
  height: 5px;
  border-radius: 10px;
  background: #4ec7ff;
  text-align: center;
}
.work_item {
  width: 33%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}
.work_item .img {
  width: 90px;
  height: 90px;
}
.work_item img {
  width: 100%;
  height: 100%;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0;
}
.introduce {
  width: 100%;
  /* height: calc(100vh -280px);
  overflow: scroll; */
  background: #fefefe;
  /* top: 50%; */
}
.introduce_top {
  width: 85%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1px 13px rgb(92, 87, 87);
  border-radius: 40px;
}
.introduce_m {
  width: 85%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
.introduce_title {
  font-size: 20px;
  text-align: left;
  border-left: 5px solid #5aa8ee;
  padding-left: 10px;
}
.introduce_title2 {
  font-size: 20px;
  text-align: left;
  border-left: 5px solid #5aa8ee;
  padding-left: 10px;
}
.introduce_content {
  font-size: 18px;
  text-align: left;
}
.introduce_m_content {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}
.introduce_m_content img {
  width: 100%;
  height: 100%;
}
.introduce_bottom {
  width: 100%;

  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.introduce_bottom_Item {
  display: flex;
  /* padding-left:20px; */
  align-items: center;
  flex-direction: column;
}

.introduce_bottom_Item img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.honor {
  background: #f1f0f0;
  padding-top: 20px;
  box-sizing: border-box;
}
.honor_title {
  font-size: 24px;
  color: #333232;
  margin: 0 auto;
}
.honor_content {
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.honor_content_Item {
  width: 42%;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: #fff;
}
.pagination {
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.news {
  width: 100%;
  height: 90%;
  padding: 30px;
  box-sizing: border-box;
}
.news_title {
  font-size: 18px;
  color: #1f1f1f;
  text-align: left;
  margin-bottom: 15px;
  font-weight: bold;
}
.new_content {
  box-shadow: 0px 1px 13px rgb(92, 87, 87);
  border-radius: 10px;
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 95%;
  height: 78%;
  padding: 20px;
  box-sizing: border-box;
}
.new_content_ItemLit {
  display: flex;
  justify-content: flex-start;
  height: 70%;
}
.new-content_Item {
  margin-left: 15px;
}
.new_content img {
  width: 100%;
  height: 100%;
}
.new_content_title {
  text-align: left;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 改 */

.companyInfo {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: normal;
}
.companyInfoText {
  font-size: 30px;
  line-height: 57px;
  color: #000;
  padding: 0 20px;
  box-sizing: border-box;
  font-weight: normal;
  text-indent: 2em;
}
.newIntroduce {
  width: 60%;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.29);
  padding: 20px 30px 30px 30px;
  box-sizing: border-box;
  border-radius: 30px;
  text-align: left;
  margin: -110px auto 0;
  /* position: absolute;
  top: 10%;
  left: 50%; */
}
.titles {
  font-size: 25px;
  text-align: center;
  color: #000;
}
.typeName {
  font-size: 20px;
  font-weight: 550;
  margin: 20px;
}
.typeContent {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-indent: 2em;
}
.Culture {
  margin-top: 20px;
  background: #f8f8f8;
}
.culTypeName {
  font-size: 20px;
  margin-top: 30px;
}
.Culture_four {
  width: 100%;

  box-sizing: border-box;
  background: #f8f8f8;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.Culture_four_Item {
  background: #fff;
  border-radius: 4px;
  padding: 40px;
  height: 250px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}
.Culture_four_Item_left {
  width: 25%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
}
.Culture_four_Item_left span {
  font-size: 20px;
}
.Culture_four_Item_right {
  width: 80%;
  height: 180px;
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  color: #918f8f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Culture_four_Item_right span {
  font-size: 20px;
  line-height: 30px;
}
.Culture_four_Item:nth-child(odd) .Culture_four_Item_left {
  background-image: linear-gradient(#fff, #eef2fb);
}
.Culture_four_Item:nth-child(even) .Culture_four_Item_left {
  background-image: linear-gradient(#eef2fb, #fff);
}
.Culture_four_Item:nth-child(odd) .Culture_four_Item_right {
  background-image: linear-gradient(#eef2fb, #fff);
}
.Culture_four_Item:nth-child(even) .Culture_four_Item_right {
  background-image: linear-gradient(#fff, #eef2fb);
}

/* 
.Culture_four .Culture_four_Item:nth-child(1) {

  background-image: linear-gradient(#fff, #eef2fb);
}
.Culture_four .Culture_four_Item:nth-child(2) {

  background-image: linear-gradient(#eef2fb, #fff);
} */
.Culture_four_Item_img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  padding-top: 20px;
}
.Culture_four_Item_img img {
  width: 80px;
  height: 80px;
}

.Culture_four_Item_title {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}
.wish {
  background: #fff;
  box-sizing: border-box;
  padding-top: 60px;
  display: flex;
  justify-content: space-evenly;
}
.wish_left {
  width: 30%;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.wish_left_title1 {
  text-align: left;
  margin-left: 20px;
  font-weight: bold;
  font-size: 16px;
}
.wish_left_title2 {
  font-size: 16px;
  font-weight: bold;
}
.border {
  width: 150px;
  height: 7px;
  background: #4ec7ff;
  border-radius: 3.5px;
  margin: 0 auto;
}
.wish_right {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  box-sizing: border-box;
  margin: 0 auto;
}
.wish_right_Item {
  background: #f9f9f9;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 13px;
}
.border2 {
  position: absolute;
  top: -20px;
  left: 40px;
  display: flex;
}
.border2 div {
  width: 12px;
  height: 40px;
  background: #fbc923;
  border-radius: 8.5px;
  margin-left: 10px;
}
.wish_right_Item_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wish_right_Item_title {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
}
.wish_right_Item_img {
  width: 120px;
  height: 120px;
}
.wish_right_Item_img img {
  width: 100%;
  height: 100%;
}
.wish_right_Item span {
  text-align: left;
  font-size: 18px;
}
</style>